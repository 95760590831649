
const PurchasesRoutes = [
    {
        path: 'prch/',
        component: () => import('@/components/sbx/purchases/PurchasesView.vue')
    },

    {
        path: 'prch/requirements',
        component: () => import('@/components/sbx/purchases/requirements/RequirementsView.vue')
    },

    {
        path: 'prch/newpurchase',
        component: () => import('@/components/sbx/purchases/purchases_orders/PurchasesOrdersView.vue')
    },
    {
        path: 'prch/purchase-orders-drum',
        component: () => import('@/components/sbx/purchases/tac/PurchaseOrdersDrum.vue'),
    }
]

export {
    PurchasesRoutes
}